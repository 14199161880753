import React from "react"
import {
  FaGithubSquare,
  FaLinkedin,
  FaDiscord,
  FaTwitterSquare,
  FaStackOverflow,
  FaGoodreads,
} from "react-icons/fa"

const data = [
  {
    id: 1,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/in/abhishekdujari/",
  },
  {
    id: 2,
    icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
    url: "https://twitter.com/abshkd",
  },
  {
    id: 3,
    icon: <FaGithubSquare className="social-icon"></FaGithubSquare>,
    url: "https://github.com/abshkd",
  },
  {
    id: 4,
    icon: <FaDiscord className="social-icon"></FaDiscord>,
    url: "https://discord.gg/UduKXdnDzH",
  },
  {
    id: 5,
    icon: <FaStackOverflow className="social-icon"></FaStackOverflow>,
    url: "https://stackoverflow.com/users/614355/abhishek-dujari",
  },
  {
    id: 6,
    icon: <FaGoodreads className="social-icon"></FaGoodreads>,
    url: "https://www.goodreads.com/adujari",
  },
]
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link">
        {link.icon}
      </a>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}
